import React from 'react';

function Footer() {
  return (
    <div className='footer'>
      <h3>Realty Shopee &copy; 2024</h3>
    </div>
  );
}

export default Footer;
